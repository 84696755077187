<template>
    <div class="px-2 mt-1">
        <b-tabs
        active-tab-class="p-0 "
        pills
        nav-class="mb-0"
        active-nav-item-class="bg-info box-shadow-info border-info info"
        >
            <b-tab
            lazy
            title="Active"
            :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
            >
                  <table-accesibility :DataStatus="1" />
            </b-tab>
            
            <b-tab
            lazy
            title="Inactive"
            :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
            >
                  <table-accesibility :DataStatus="0" />
            </b-tab>
        </b-tabs>
    </div>
</template>        
<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
    components: {  
        TableAccesibility: () => import(/* webpackChunkName: "Business/Settings: TableAccesibility" */ "./TableAccesibility.vue"),
    }, 
    data(){
        return {
            
        }
    },
    created: function(){   
        
    },    
    mounted(){   
        
    },     
    methods:{
        
    },
    computed:{
        
    }
}
</script>